import request from '@/api/axios-request'

const apiTransaction = {

  list(page, limit, sortColumn = null, sortOrder = null, filters = {}) {
    return request({
      url: '/ad/transactions/list',
      method: 'get',
      params: {
        page: page,
        limit: limit,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        filters: encodeURI(JSON.stringify(filters))
      }
    })
  },

  get(id) {
    return request({
      url: '/ad/transactions/get',
      method: 'post',
      data: {
        id
      }
    })
  },

  approveCaTxn(id, amount, remarks = null) {
    return request({
      url: '/ad/transactions/approveCaTxn',
      method: 'post',
      data: {
        id,
        amount,
        remarks
      }
    })
  },

  voidCaTxn(id, voidCode, remarks = null) {
    return request({
      url: '/ad/transactions/voidCaTxn',
      method: 'post',
      data: {
        id,
        voidCode,
        remarks
      }
    })
  },

}

export default apiTransaction